<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '750px'"
    @keydown.esc="cancel"
  >
    <!-- preview -->
    <v-card
      v-if="!viewMore && !reportAnotherFalseNegative"
      class="px-8 py-4"
      variant="elevated"
      :width="$vuetify.breakpoint.mobile ? '100%' : '750px'"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text ">
          {{ $t('deconve.personFalseNegatives') }}
        </v-toolbar-title>
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>

      <v-card-text
        class="px-4 mt-2"
        style="padding-bottom: 0px"
      >
        <div
          v-if="!hasFalseNegatives"
          class="d-flex align-center mt-4"
        >
          <span class="font-weight-medium caption text-sm-body-1 mb-2">
            {{ $t('deconve.messageForNoFalseNegative') }}
          </span>
        </div>
        <div
          v-for="(falseNegatives, index) in personFalseNegatives"
          :key="falseNegatives.id"
          class="d-flex align-center mt-4"
        >
          <div
            style="display: flex; align-items: center; width: 100%;"
            class="mt-8"
            :class="{ 'flex-column-reverse': $vuetify.breakpoint.mobile }"
          >
            <div class="d-flex flex-wrap flex-column">
              <file-item
                v-if="!$vuetify.breakpoint.mobile"
                :key="falseNegatives.id"
                :name="videoChipNameCropped(falseNegatives.video_url)"
              />
              <rectangle-button
                class="mt-4"
                outlined
                color="neutral"
                @clicked="openViewMore(index, falseNegatives.id)"
              >
                {{ $t('deconve.viewMore') }}
              </rectangle-button>
            </div>
            <v-spacer />
            <div style="max-width: 100%">
              <div class="d-flex flex-wrap flex-column">
                <span class="font-weight-medium caption text-sm-body-1 mb-4">
                  {{ $t('deconve.occurrenceStatus') }}
                </span>
                <span
                  class="font-weight-bold text-sm-body-1"
                  style="max-width: 165px; word-wrap: break-word;"
                  :style="{ color: statusColor(falseNegatives.status) }"
                >
                  {{ getStatusValues(falseNegatives.status) }}
                </span>
              </div>
            </div>
            <v-spacer />
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="mt-8">
        <v-spacer />
        <rectangle-button
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          color="primary"
          :icon="$vuetify.breakpoint.mobile? 'mdi-alert' : ''"
          @clicked="reportFalseNegative"
        >
          <div class="d-none d-md-flex">
            {{ $t('deconve.detailFalseNegative') }}
          </div>
        </rectangle-button>
      </v-card-actions>
    </v-card>

    <!-- will add a false negative -->
    <v-card
      v-else-if="reportAnotherFalseNegative"
      class="px-8 py-4"
      variant="elevated"
      :width="$vuetify.breakpoint.mobile ? '100%' : '750px'"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.reportFalseNegative') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="goBack"
        />
      </v-toolbar>

      <v-card-text
        class="px-4 mt-2"
        style="padding-bottom: 0px"
      >
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1">
            {{ $t('deconve.unit') }}
          </span>
          <v-btn
            outlined
            color="neutral"
            :small="$vuetify.breakpoint.mobile"
            @click="openUnitModal"
          >
            <v-icon :left="!$vuetify.breakpoint.mobile">
              {{ icons.storePlus }}
            </v-icon>

            <div v-if="!$vuetify.breakpoint.mobile">
              {{ $t('deconve.selectUnit') }}
            </div>
          </v-btn>
        </div>

        <div class="d-flex justify-space-between align-center mt-4">
          <v-col class="pa-0">
            <v-alert
              v-if="isToShowUnitNotFoundMessage"
              dense
              type="error"
              color="warn"
            >
              {{ $t('deconve.unitNotFound', [unitId]) }}
            </v-alert>

            <div
              v-if="unitName"
              class="d-flex flex-wrap"
            >
              <v-chip
                label
                outlined
              >
                {{ unitName }}
              </v-chip>
            </div>
          </v-col>
        </div>

        <div class="d-flex align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1 mb-n3">
            {{ $t('deconve.video') }}
          </span>
        </div>
        <div class="d-flex align-center mt-6">
          <v-autocomplete
            v-model="selectedVideoId"
            :items="videos"
            chips
            dense
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
          />
        </div>
        <div class="d-flex align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1 mb-n3">
            {{ $t('deconve.occurrenceStatus') }}
          </span>
        </div>
        <div class="d-flex align-center mt-6">
          <input-options
            v-model="falseNegativeStatus"
            min-width="100px"
            :options="statusValues"
          />
        </div>
        <div class="d-flex align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1 mb-n3">
            {{ $t('deconve.about') }}
          </span>
        </div>
        <div class="d-flex align-center mt-6">
          <v-textarea
            v-model="about"
            :counter="maxAboutTextLength"
            :rules="aboutRules"
            class="body-2 text-sm-body-1"
            auto-grow
            outlined
          />
        </div>

        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1">
            {{ $t('deconve.originialOccurrenceVideo') }}
          </span>
        </div>
        <div class="d-flex align-center mt-6">
          <alert
            type="warning"
            color="warn"
          >
            <div>
              {{ $t('deconve.originalVideoWarning') }}
            </div>
          </alert>
        </div>
        <v-sheet
          v-if="selectedVideo === null"
          elevation="0"
          class="d-flex justify-center align-center dropbox pa-6 mt-2"
        >
          <input
            type="file"
            accept="video/mp4"
            class="input-file"
            required
            @click="clearInputFiles"
            @change="loadVideo($event.target.files)"
          >
          <div style="display: flex; flex-direction: column; align-items: center">
            <v-icon
              :size="$vuetify.breakpoint.mobile? 28 : 32"
              class="mb-4"
              color="info"
            >
              {{ icons.videoFile }}
            </v-icon>
            <span
              style="color: #8F93FF; text-align: center"
              class="d-flex align-center body-2 text-sm-body-1"
            >
              {{ $t('deconve.videoFileInput') }}
            </span>
          </div>
        </v-sheet>
        <div
          v-else
          class="d-flex align-center mt-2"
        >
          <div style="display: flex; align-items: center; width: 100%;">
            <file-item
              :name="videoChipName(selectedVideo.name)"
              is-edit-mode
              @delete="removeVideo"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer />
        <rectangle-button
          outlined
          color="neutral"
          @clicked="goBack"
        >
          {{ $t('deconve.goBack') }}
        </rectangle-button>
        <rectangle-button
          :disabled="areRequiredFieldsMissing"
          :is-loading="isSendingReport"
          color="primary"
          @clicked="confirm"
        >
          {{ $t('deconve.submit') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>

    <!-- it's seeing that false negative -->
    <v-card
      v-else-if="viewMore"
      class="px-8 py-4"
      variant="elevated"
      :width="$vuetify.breakpoint.mobile ? '100%' : '750px'"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.falseNegative') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="goBack"
        />
      </v-toolbar>

      <v-card-text
        class="px-4 mt-2"
        style="padding-bottom: 0px"
      >
        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1">
            {{ $t('deconve.unit') }}
          </span>
        </div>

        <div class="d-flex justify-space-between align-center mt-4">
          <v-col class="pa-0">
            <v-alert
              v-if="isToShowUnitNotFoundMessage"
              dense
              type="error"
              color="warn"
            >
              {{ $t('deconve.unitNotFound', [falseNegativeUnitId]) }}
            </v-alert>

            <div
              v-if="falseNegativeUnitName"
              class="d-flex flex-wrap"
            >
              <v-chip
                label
                outlined
              >
                {{ falseNegativeUnitName }}
              </v-chip>
            </div>
            <div
              v-else
              class="d-flex flex-wrap"
            >
              <v-skeleton-loader
                loading
                class="mt-4"
                type="chip"
              />
            </div>
          </v-col>
        </div>

        <div class="d-flex align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1 mb-n3">
            {{ $t('deconve.video') }}
          </span>
        </div>
        <div class="d-flex align-center mt-6">
          <v-skeleton-loader
            v-if="falseNegativeReportIsLoading"
            loading
            class="mx-auto"
            width="200px"
            type="list-item"
          />
          <span
            v-else
            class="font-weight-medium caption text-sm-body-2 mb-1"
            style="width: 100%"
          >
            {{ videoName }}
          </span>
        </div>
        <div class="d-flex align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1 mb-n3">
            {{ $t('deconve.occurrenceStatus') }}
          </span>
        </div>
        <div class="d-flex align-center mt-6">
          <span
            class="font-weight-bold caption text-sm-body-2 mb-1"
            :style="{ color: statusColor(statusInfo) }"
          >
            {{ getStatusValues(statusInfo) }}
          </span>
        </div>
        <div class="d-flex align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1 mb-n3">
            {{ $t('deconve.about') }}
          </span>
        </div>
        <div class="disableDiv d-flex align-center mt-6">
          <v-textarea
            v-model="aboutInfo"
            :counter="maxAboutTextLength"
            :rules="aboutRules"
            class="body-2 text-sm-body-1"
            auto-grow
            outlined
          />
        </div>

        <div class="d-flex justify-space-between align-center mt-4">
          <span class="font-weight-medium caption text-sm-body-1">
            {{ $t('deconve.originialOccurrenceVideo') }}

          </span>
        </div>
        <div
          v-if="videoChipIsReady"
          class="d-flex align-center mt-4"
        >
          <file-item
            :name="selectedVideo.name"
            @open="downloadVideo"
          />
        </div>
        <div
          v-else-if="!videoChipIsReady"
          style="display: flex; align-items: center; width: 100%;"
        >
          <v-skeleton-loader
            loading
            class="mx-auto mt-4"
            width="100%"
            height="100%"
            type="chip"
          />
        </div>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer />
        <rectangle-button
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          @clicked="goBack"
        >
          {{ $t('deconve.goBack') }}
        </rectangle-button>
        <rectangle-button
          color="warn"
          @clicked="deleteFalseNegativeChecker"
        >
          {{ $t('deconve.delete') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>

    <unit-selection-dialog ref="unitDialog" />
    <div v-if="alert && !isLoading && alertMessageText">
      <v-snackbar
        v-model="alert"
        data-cy="snackbar"
        :color="getAlertColor"
      >
        {{ alertMessageText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="resetAlert"
          >
            <v-icon color="white">
              {{ icons.close }}
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-dialog
      v-model="deleteCheck"
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
      @keydown.esc="cancel"
    >
      <v-card
        class="px-8 py-4"
        variant="elevated"
        :width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
        :loading="isLoading"
      >
        <v-toolbar
          color="white"
          dense
          flat
          class="align-center"
        >
          <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
            {{ $t('deconve.deleteFalseNegative') }}
          </v-toolbar-title>
          <v-spacer />
          <square-button
            v-if="$vuetify.breakpoint.mobile"
            menu-disabled
            icon-name="mdi-close"
            outlined
            color="transparentBackground"
            style="margin-top: auto"
            icon-color="neutral"
            @clicked="goBack"
          />
        </v-toolbar>
        <v-card-text
          class="px-4 mt-2"
          style="padding-bottom: 0px"
        >
          <span class="font-weight-medium caption text-sm-body-1 mt-4">
            {{ $t('deconve.deleteFalseNegativeMessage') }}
          </span>
          <v-card-text />
          <v-card-actions class="mt-4">
            <v-spacer />
            <rectangle-button
              v-if="!$vuetify.breakpoint.mobile"
              outlined
              color="neutral"
              @clicked="goBack"
            >
              {{ $t('deconve.goBack') }}
            </rectangle-button>
            <rectangle-button
              color="warn"
              @clicked="deleteFalseNegativeReport"
            >
              {{ $t('deconve.delete') }}
            </rectangle-button>
          </v-card-actions>
          <v-card />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiPlayBoxOutline, mdiStorePlusOutline, mdiClose, mdiReload,
} from '@mdi/js';
import UnitSelectionDialog from '@/components/UnitSelectionDialog.vue';
import InputOptions from '@/components/InputOptions.vue';
import Alert from '@/components/Alert.vue';
import { FALSE_NEGATIVE_STATUS } from '@/utils/falseNegativeStatus';
import FileItem from '@/components/FileItem.vue';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';

export default {
  name: 'ReportFalseNegativeDialog',
  components: {
    RectangleButton,
    SquareButton,
    UnitSelectionDialog,
    InputOptions,
    Alert,
    FileItem,
  },
  data: () => ({
    dialog: false,
    warningCheckbox: false,
    resolve: null,
    reject: null,
    alert: false,
    alertMessage: '',
    alertColor: '',
    about: '',
    hasFalseNegatives: false,
    reportAnotherFalseNegative: false,
    viewMore: false,
    showUnitsInfo: false,
    showFalseNegativeInfo: false,
    loadingVideo: true,
    selectedVideoId: null,
    deleteCheck: false,
    selectedVideo: null,
    selectedVideoFile: null,
    falseNegativeStatus: 0,
    falseNegativeIndex: 0,
    falseNegativeId: '',
    maxAboutTextLength: 420,
    videoId: null,
    personId: null,
    falseNegativeUnitName: '',
    falseNegativeUnitId: '',
    unit: null,
    personData: null,
    isCurrentUnitIdValid: true,
    falseNegativeReportIsLoading: false,
    videoChipIsReady: false,
    isSendingReport: false,
    isLoading: false,
    icons: {
      storePlus: mdiStorePlusOutline,
      videoFile: mdiPlayBoxOutline,
      reload: mdiReload,
      close: mdiClose,
    },
  }),
  computed: {
    ...mapGetters({
      videos: 'videos/videos',
      getPerson: 'faceid/getPerson',
      personFalseNegatives: 'faceid/getPersonFalseNegatives',
    }),
    alertMessageText() {
      return this.alertMessage;
    },
    getAlertColor() {
      return this.alertColor;
    },
    personDataRef() {
      return this.getPerson(this.personId);
    },
    unitId() {
      return this.personData?.unit?.id;
    },
    isToShowUnitNotFoundMessage() {
      return !this.isCurrentUnitIdValid && !this.unitName;
    },
    statusOptions() {
      return {
        stole: this.$t('deconve.stole'),
        triedToSteal: this.$t('deconve.triedToSteal'),
        didNotTryToSteal: this.$t('deconve.didNotTryToSteal'),
      };
    },
    statusValues() {
      return [
        this.statusOptions.stole,
        this.statusOptions.triedToSteal,
        this.statusOptions.didNotTryToSteal,
      ];
    },
    falseNegativeStatusValues() {
      return [
        FALSE_NEGATIVE_STATUS.stole,
        FALSE_NEGATIVE_STATUS.tried_to_steal,
        FALSE_NEGATIVE_STATUS.did_not_try_to_steal,
      ];
    },
    statusColor() {
      return (status) => {
        switch (status) {
          case FALSE_NEGATIVE_STATUS.stole:
            return this.$vuetify.theme.currentTheme.falseNegativeStatusStole;
          case FALSE_NEGATIVE_STATUS.tried_to_steal:
            return this.$vuetify.theme.currentTheme.falseNegativeStatusTriedToSteal;
          case FALSE_NEGATIVE_STATUS.did_not_try_to_steal:
            return this.$vuetify.theme.currentTheme.falseNegativeStatusDidNotTryToSetal;
          default:
            return this.$vuetify.theme.currentTheme;
        }
      };
    },
    unitName() {
      return this.unit?.name;
    },
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    aboutInfo() {
      return this.personFalseNegatives[this.falseNegativeIndex]?.about;
    },
    statusInfo() {
      return this.personFalseNegatives[this.falseNegativeIndex]?.status;
    },
    falseNegativeReportData() {
      return this.personFalseNegatives
        .find((falseNegative) => falseNegative.id === this.falseNegativeId);
    },
    videoChipName() {
      return (videoUrl) => videoUrl.split('/').pop().split('?')[0];
    },
    videoChipNameCropped() {
      return (videoUrl) => {
        const fileName = this.videoChipName(videoUrl);

        return fileName.length <= 24 ? `${fileName}.mp4` : `${fileName.slice(0, 18)}...mp4`;
      };
    },
    getStatusValues() {
      return (status) => {
        const statusValuesIndex = this.falseNegativeStatusValues.indexOf(status);

        return statusValuesIndex !== -1 ? this.statusValues[statusValuesIndex] : '';
      };
    },
    videoName() {
      return this.videos.find((videoId) => videoId.id === this.selectedVideoId)?.name;
    },
    areRequiredFieldsMissing() {
      return this.unit === null || this.selectedVideoId === null
        || this.about === '' || this.selectedVideo === null;
    },
  },
  watch: {
    personDataRef() {
      this.personData = this.personDataRef;
    },
    unit() {
      this.fetchUnit(this.unit.id).then(({ videos }) => {
        this.getVideoId(videos);
      });
    },
  },
  created() {
    this.isLoading = true;

    this.fetchVideos().then(() => {
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      addPersonFalseNegatives: 'faceid/addPersonFalseNegatives',
      fetchUnit: 'units/fetchUnit',
      fetchVideos: 'videos/fetchVideos',
      fetchPersonFalseNegatives: 'faceid/fetchPersonFalseNegatives',
      deleteFalseNegative: 'faceid/deletePersonFalseNegative',
    }),
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
      this.isSendingReport = false;
    },
    clearInputFiles(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    open({ personId }) {
      this.personId = personId;
      this.fetchPersonFalseNegatives(this.personId).then(() => {
        this.handlePersonFalseNegatives();
      });
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getUpdatedFalseNegativesList() {
      this.fetchPersonFalseNegatives(this.personId).then((personFalseNegatives) => {
        if (personFalseNegatives.length > 0) {
          this.hasFalseNegatives = true;
        } else {
          this.hasFalseNegatives = false;
        }
      });
    },
    handlePersonFalseNegatives() {
      this.isLoading = true;

      if (this.personFalseNegatives.length > 0) {
        this.hasFalseNegatives = true;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    openUnitModal() {
      const unitId = this.unit?.id;

      this.$refs.unitDialog.open(unitId).then((selectedUnit) => {
        this.unit = selectedUnit;
      }).catch(() => {
        // User canceled the dialog
      });
    },
    getVideoId(unitVideos) {
      const findFirstMatchingId = (video) => this.videos.some(({ id }) => id === video.id);

      const matchingVideo = unitVideos.find(findFirstMatchingId);

      this.selectedVideoId = matchingVideo ? matchingVideo.id : null;
    },
    getCurrentVideoId() {
      this.falseNegativeReportIsLoading = true;

      const {
        unit: { id: falseNegativeUnitId },
        video: { id: selectedVideoId },
      } = this.falseNegativeReportData;

      this.falseNegativeUnitId = falseNegativeUnitId;
      this.fetchUnit(falseNegativeUnitId).then(({ name }) => {
        this.falseNegativeUnitName = name;
      });
      this.selectedVideoId = selectedVideoId;

      this.falseNegativeReportIsLoading = false;
    },
    getVideoChip() {
      this.videoChipIsReady = false;

      const { video_url: videoUrl } = this.falseNegativeReportData;

      this.selectedVideo = {
        name: this.videoChipName(videoUrl),
        file: videoUrl,
      };
      this.videoChipIsReady = true;
    },
    isVideoFileSizeAccepted(videoFile, maxFileSizeAllowedInBytes) {
      return videoFile.size <= maxFileSizeAllowedInBytes;
    },
    isFileNameSizeAccepted(videoFile, maxFileNameSize) {
      return videoFile.name.length <= maxFileNameSize;
    },
    loadVideo(videoFiles) {
      const maxFileNameSize = 128;
      const maxFileSizeAllowedInBytes = 20971520; // 20 MB

      const videoFile = videoFiles[0];

      this.selectedVideoFile = videoFile;

      if (!this.isVideoFileSizeAccepted(videoFile, maxFileSizeAllowedInBytes)) {
        this.showAlert('warn', this.$t('deconve.personFalseNegativeFileSizeTooBig'));
        return;
      }

      if (!this.isFileNameSizeAccepted(videoFile, maxFileNameSize)) {
        this.showAlert('warn', this.$t('deconve.personFalseNegativeFileNameTooLong'));
        return;
      }

      this.selectedVideo = {
        name: videoFile.name,
        file: URL.createObjectURL(videoFile),
      };
    },
    confirm() {
      this.isLoading = true;
      this.isSendingReport = true;

      const formData = new FormData();

      formData.append('unit_id', this.unit.id);
      formData.append('video_id', this.selectedVideoId);
      formData.append('video', this.selectedVideoFile, this.selectedVideo.name);
      formData.append('about', this.about);
      formData.append('status', this.falseNegativeStatusValues[this.falseNegativeStatus]);

      this.addPersonFalseNegatives({ personId: this.personId, formData }).then(() => {
        this.videoChipIsReady = false;
        this.showAlert('info', this.$t('deconve.messageForAddNewFalseNegative'));
        this.reportAnotherFalseNegative = false;
        this.goBack();
        this.getUpdatedFalseNegativesList();
        this.isLoading = false;
      });
    },
    downloadVideo() {
      const { file } = this.selectedVideo;

      window.open(file);
    },
    cancel() {
      this.dialog = false;
      this.hasFalseNegatives = false;
      this.resolve(true);
    },
    openViewMore(index, id) {
      this.viewMore = true;
      this.falseNegativeId = id;
      this.getCurrentVideoId();
      this.getVideoChip();
      this.videoChipIsReady = true;
      this.falseNegativeIndex = index;
    },
    reportFalseNegative() {
      this.selectedVideoId = null;
      this.reportAnotherFalseNegative = true;
    },
    goBack() {
      this.viewMore = false;
      this.selectedVideo = null;
      this.deleteCheck = false;
      this.reportAnotherFalseNegative = false;
      this.falseNegativeIndex = 0;
    },
    deleteFalseNegativeChecker() {
      this.deleteCheck = true;
    },
    removeVideo() {
      this.selectedVideo = null;
    },
    deleteFalseNegativeReport() {
      this.isSendingReport = true;

      this.deleteFalseNegative(
        { personId: this.personId, falseNegativeId: this.falseNegativeId },
      ).then(() => {
        this.videoChipIsReady = false;
        this.showAlert('warn', this.$t('deconve.deletedFalseNegativeMessage'));
        this.deleteCheck = false;
        this.viewMore = false;
        this.goBack();
        this.getUpdatedFalseNegativesList();
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
textarea:focus {
  outline: 0;
}

video {
  border-radius: 4px !important;
}

.disableDiv {
  pointer-events: none !important;
}
</style>
