<template>
  <div>
    <v-chip
      color="primaryHighlight"
      :style="{color: '#111111'}"
      :small="small"
      :data-cy="'tag-files'"
      :close="isEditMode"
      @click:close="isEditMode ? $emit('delete') : null"
      @click="isEditMode ? null : $emit('open')"
    >
      {{ name }}
    </v-chip>
  </div>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

export default {
  name: 'FileItem',
  props: {
    name: { type: String, required: true },
    small: { type: Boolean, default: false },
    isEditMode: { type: Boolean, default: false },
  },
  data: () => ({}),
};
</script>
